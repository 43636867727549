var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"price-list-list-container"},[(_vm.priceListList && _vm.priceListList.length > 0)?_c('div',{staticClass:"price-list-list-"},[_c('v-row',{staticClass:"price-list-list-headers table-header hidden-xs-only text-uppercase mt-3"},[_c('v-col',{staticClass:"price-list-list-header-col",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.$t("priceList.priceListList.header.priceListName"))+" ")]),_c('v-col',{staticClass:"price-list-list-header-col",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t("priceList.priceListList.header.status"))+" ")]),_c('v-col',{staticClass:"price-list-list-header-col",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t("priceList.priceListList.header.beginDate"))+" ")]),_c('v-col',{staticClass:"price-list-list-header-col",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t("priceList.priceListList.header.endDate"))+" ")]),_c('v-col',{staticClass:"price-list-list-header-col",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t("priceList.priceListList.header.id"))+" ")])],1),(_vm.isPriceListProposal)?_c('v-list',{staticClass:"price-list-list",attrs:{"flat":""}},_vm._l((_vm.priceListList),function(priceList){return _c('v-list-item',{key:priceList.priceListProposalId,staticClass:"price-list-list-item",attrs:{"to":{
          name: 'PriceListProposalDetail',
          params: { priceListProposalId: priceList.priceListProposalId }
        }}},[_c('PriceListListRow',{attrs:{"priceList":priceList.priceList,"publicationState":priceList.publicationState}})],1)}),1):_c('v-list',{staticClass:"price-list-list",attrs:{"flat":""}},_vm._l((_vm.priceListList),function(priceList){return _c('v-list-item',{key:priceList.priceListId,staticClass:"price-list-list-item",attrs:{"to":{
          name: 'PriceListDetail',
          params: { priceListId: priceList.priceListId }
        }}},[_c('PriceListListRow',{attrs:{"priceList":priceList,"status":_vm.$dayjs().isBefore(_vm.$dayjs(priceList.toDate).add(1, 'day')) ? 1 : 0}})],1)}),1)],1):(_vm.loaded)?_c('v-card',{staticClass:"no-price-list-list",attrs:{"outlined":""}},[_c('v-card-title',[(_vm.isPriceListProposal)?[_vm._v(" "+_vm._s(_vm.$t("priceList.priceListList.noPriceListProposalList"))+" ")]:[_vm._v(" "+_vm._s(_vm.$t("priceList.priceListList.noPriceListList"))+" ")]],2)],1):_vm._e(),(!_vm.isPriceListProposal)?_c('v-btn',{staticClass:"mt-5",attrs:{"color":"primary","outlined":""},on:{"click":_vm.openNewPriceListProposal}},[_vm._v(" "+_vm._s(_vm.$t("priceList.priceListList.newProposalBtn"))+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }