<template>
  <div class="price-list-list-container">
    <div
      class="price-list-list-"
      v-if="priceListList && priceListList.length > 0"
    >
      <v-row
        class="price-list-list-headers table-header hidden-xs-only text-uppercase mt-3"
      >
        <v-col cols="4" class="price-list-list-header-col">
          {{ $t("priceList.priceListList.header.priceListName") }}
        </v-col>
        <v-col cols="2" class="price-list-list-header-col">
          {{ $t("priceList.priceListList.header.status") }}
        </v-col>
        <v-col cols="2" class="price-list-list-header-col">
          {{ $t("priceList.priceListList.header.beginDate") }}
        </v-col>
        <v-col cols="2" class="price-list-list-header-col">
          {{ $t("priceList.priceListList.header.endDate") }}
        </v-col>
        <v-col cols="2" class="price-list-list-header-col">
          {{ $t("priceList.priceListList.header.id") }}
        </v-col>
      </v-row>
      <v-list v-if="isPriceListProposal" flat class="price-list-list">
        <v-list-item
          class="price-list-list-item"
          v-for="priceList in priceListList"
          :key="priceList.priceListProposalId"
          :to="{
            name: 'PriceListProposalDetail',
            params: { priceListProposalId: priceList.priceListProposalId }
          }"
        >
          <PriceListListRow
            :priceList="priceList.priceList"
            :publicationState="priceList.publicationState"
          />
        </v-list-item>
      </v-list>
      <v-list v-else flat class="price-list-list">
        <v-list-item
          class="price-list-list-item"
          v-for="priceList in priceListList"
          :key="priceList.priceListId"
          :to="{
            name: 'PriceListDetail',
            params: { priceListId: priceList.priceListId }
          }"
        >
          <PriceListListRow
            :priceList="priceList"
            :status="
              $dayjs().isBefore($dayjs(priceList.toDate).add(1, 'day')) ? 1 : 0
            "
          />
        </v-list-item>
      </v-list>

      <!-- <v-pagination
          v-if="priceListListPage && priceListListPage.totPages > 1"
          :value="pageFilter"
          :page="priceListListPage.selPage"
          :length="priceListListPage.totPages ? priceListListPage.totPages : 0"
          :totalVisible="6"
          @next.stop.prevent="handlePageFilter"
          @prev="handlePageFilter"
          @input="handlePageFilter"
        ></v-pagination> -->
    </div>
    <v-card outlined v-else-if="loaded" class="no-price-list-list">
      <v-card-title>
        <template v-if="isPriceListProposal">
          {{ $t("priceList.priceListList.noPriceListProposalList") }}
        </template>
        <template v-else>
          {{ $t("priceList.priceListList.noPriceListList") }}
        </template>
      </v-card-title>
    </v-card>

    <v-btn
      class="mt-5"
      color="primary"
      outlined
      @click="openNewPriceListProposal"
      v-if="!isPriceListProposal"
    >
      {{ $t("priceList.priceListList.newProposalBtn") }}
    </v-btn>
  </div>
</template>
<style lang="scss"></style>
<script>
import PriceListListRow from "@/components/priceList/PriceListListRow.vue";
import InsertPriceListProposal from "@/components/priceList/InsertPriceListProposal.vue";

import reload from "~/mixins/reload";
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";

import priceListService from "~/service/priceListService";
import priceListProposalService from "~/service/priceListProposalService";

export default {
  name: "PriceListList",
  mixins: [reload, cartInfo, login],
  components: {
    PriceListListRow
  },
  props: {
    isPriceListProposal: { type: Boolean, default: false }
  },
  data() {
    return {
      loaded: false,
      priceListListPage: {},
      priceListList: null,
      errorMessage: {}
    };
  },
  computed: {
    // ...mapGetters({
    //   pageFilter: "category/pageFilter"
    // })
  },
  methods: {
    async openNewPriceListProposal() {
      let newProposal = await this.$dialog.show(InsertPriceListProposal, {
        waitForResult: true
      });
      if (newProposal && newProposal === "changeWharehouse") {
        await this.openAddressSelector();
        setTimeout(() => {
          this.openNewPriceListProposal();
        }, 300);
      } else if (newProposal && newProposal.priceListProposalId) {
        this.$router.push({
          name: "PriceListProposalDetail",
          params: {
            priceListProposalId: newProposal.priceListProposalId
          }
        });
      }
    },
    async reload() {
      this.loaded = false;
      if (this.isPriceListProposal) {
        await this.fetchPriceListListProposal();
      } else {
        await this.fetchPriceListList();
      }
      this.loaded = true;
    },
    async fetchPriceListList() {
      const result = await priceListService.getListPersonal();
      if (result.response?.status == 0 && result.data?.priceList) {
        this.priceListList = result.data.priceList;
      } else if (result.response) {
        this.errorMessage = result.response;
      }
    },
    async fetchPriceListListProposal() {
      const result = await priceListProposalService.getList();
      if (result.response?.status == 0 && result.data?.priceListProposal) {
        this.priceListList = result.data.priceListProposal;
      } else if (result.response) {
        this.errorMessage = result.response;
      }
    }

    // async deleteOrder(orderId) {
    //   await OrderService.deleteOrder(orderId);
    //   this.reload();
    // },
    // duplicatePriceList(priceListId) {
    //   OrderService.editOrder(orderId);
    // },
    // handlePageFilter(page) {
    //   this.$store.dispatch("category/setFilterPage", page);
    //   this.reload();
    //   this.$vuetify.goTo(0);
    // }
  },
  mounted() {
    this.reload();
  }
};
</script>
